import {HttpErrorResponse} from '@angular/common/http';
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';

export interface IErrorHandlerArgs {
    error: any;
    appInsightsSrv: AppInsightsService;
    fallbackMessage?: string;

    /** Usually it is class SomeService.name, but this approach can't be used due to minification, need to place 'SomeService'*/
    scope?: string;
};

export const errorHandler = (arg: IErrorHandlerArgs): void => {
    const {error, appInsightsSrv, fallbackMessage, scope} = arg;
    console.error(error);
    let trackEventMessage = '';
    if (error instanceof HttpErrorResponse) {
        trackEventMessage = JSON.stringify({...error, error: typeof error.error === 'object' ? JSON.stringify(error.error) : error.error});
    } else {
        trackEventMessage = `${fallbackMessage}${scope ? ('(scope ' + scope + ')'): ''}: ${error}`;
    }
    appInsightsSrv.trackTrace(
        {
            message: trackEventMessage,
            severityLevel: SeverityLevel.Error,
        });
}

export const httpErrorToString = (error: HttpErrorResponse, fallbackMessage = 'Unknown error'): string => {
    if (Array.isArray(error?.error) && error?.error?.[0]?.errorMessage) {
        return error?.error?.map((e) => e.errorMessage).join('\n');
    }
    return error?.error?.message ?? error?.error ?? error?.message ?? `${error.statusText} - ${fallbackMessage}`;
};